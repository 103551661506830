import styled from 'styled-components'

import Container from '@/common/components/Container'
import Gap from '@/common/components/Gap'
import { media } from '@/common/utils/viewport'

export const Wrapper = styled.div`
  padding: 100px 0 32px;
  overflow: hidden;

  ${media.down('sm')`
    padding: 85px 0 72px;
  `}
`

export const StyledContainer = styled(Container).attrs({
  className: 'px-0',
})`
  position: relative;
  z-index: 0;
`

export const TitleOverlay = styled.div`
  color: rgba(197, 58, 48, 0.05);
  font-size: 60px;
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  top: -50px;
  left: 40px;
  z-index: -1;
`

export const CardsWrapper = styled(Gap).attrs({
  className: 'align-stretch',
  space: 24,
})`
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 24px;
  padding: 24px;

  & > * {
    scroll-snap-align: start;
  }
`

import styled from 'styled-components'

import ButtonLink from '@/common/components/ButtonLink'
import Container from '@/common/components/Container'
import { media } from '@/common/utils/viewport'
import background from '@/modules/landing/pages/HomeLandingPage/images/join-with-us-background.png'

export const Wrapper = styled.div.attrs({
  className: 'text-white text-center',
})`
  background-image: url(${background});
  background-position: center;
  background-size: cover;
`

export const StyledContainer = styled(Container)`
  width: 100%;
  max-width: 580px;
  padding-top: 90px;
  padding-bottom: 120px;

  ${media.down('sm')`
    padding-top: 56px;
    padding-bottom: 56px;
  `}
`

export const StyledButtonLink = styled(ButtonLink).attrs({
  fullWidth: true,
})`
  width: 200px;
  max-width: 100%;
`

import loadable from '@loadable/component'
import cn from 'classnames'
import React, { useState } from 'react'

import Container from '@/common/components/Container'
import { PROJECT_NAME } from '@/common/constants'
import useI18n from '@/common/hooks/useI18n'
import usePermission from '@/common/hooks/usePermission'
import useSwitch from '@/common/hooks/useSwitch'
import { SIGN_UP_PATH } from '@/modules/authentication/paths'
import banner from '@/modules/landing/pages/HomeLandingPage/images/hero-banner.png'

import {
  Wrapper,
  Banner,
  ProjectName,
  Slogan,
  SearchCard,
  RegisterButton,
} from './styled'
import { REGISTER_NOW, SEARCH_BY_AREA, SEARCH_BY_NAME } from './translation'

const BasicSearchPlaceForm = loadable(
  () => import('@/modules/property/components/BasicSearchPlaceForm')
)
const SearchPlaceByNameForm = loadable(
  () => import('@/modules/property/components/SearchPlaceByNameForm')
)
const SearchPlaceModal = loadable(
  () => import('@/modules/property/components/SearchPlaceModal'),
  { ssr: false }
)

type SearchBy = 'area' | 'name'

const HeroSection = () => {
  const { t } = useI18n()
  const { isMember } = usePermission()
  const [searchBy, setSearchBy] = useState<SearchBy>('area')
  const {
    value: isOpenAdvanceSearchModal,
    toggle: toggleAdvanceSearchModal,
    off: closeAdvanceSearchModal,
  } = useSwitch()

  return (
    <>
      <Wrapper>
        <Banner src={banner} alt='banner' />
        <Container className='flex flex-column-tablet align-start align-center-tablet justify-space-between pt-40 pt-56-tablet px-32-tablet'>
          <div className='text-center-tablet ml-40 ml-0-tablet'>
            <ProjectName>{PROJECT_NAME}</ProjectName>
            <Slogan>
              <span>Right place,</span> <span>right person,</span>{' '}
              <span>right time</span>
            </Slogan>
            {!isMember ? (
              <RegisterButton to={SIGN_UP_PATH}>
                {t(REGISTER_NOW)}
              </RegisterButton>
            ) : null}
          </div>
          <SearchCard>
            <div
              className={cn(
                searchBy === 'area' ? 'pb-20' : 'pb-24',
                'px-24 pt-24'
              )}
            >
              <div className='flex justify-space-around mb-24'>
                <div
                  className={cn(
                    searchBy === 'area' && 'border-bottom-primary',
                    'text-16 text-bold clickable pb-4'
                  )}
                  onClick={() => setSearchBy('area')}
                  onMouseOver={() => BasicSearchPlaceForm.preload()}
                >
                  {t(SEARCH_BY_AREA)}
                </div>
                <div
                  className={cn(
                    searchBy === 'name' && 'border-bottom-primary',
                    'text-16 text-bold clickable pb-4'
                  )}
                  onClick={() => setSearchBy('name')}
                  onMouseOver={() => SearchPlaceByNameForm.preload()}
                >
                  {t(SEARCH_BY_NAME)}
                </div>
              </div>
              {searchBy === 'area' && (
                <BasicSearchPlaceForm
                  onClickAdvancedSearch={toggleAdvanceSearchModal}
                />
              )}
              {searchBy === 'name' && <SearchPlaceByNameForm />}
            </div>
          </SearchCard>
        </Container>
      </Wrapper>
      <SearchPlaceModal
        mode='advance'
        isOpen={isOpenAdvanceSearchModal}
        onClose={closeAdvanceSearchModal}
      />
    </>
  )
}

export default HeroSection

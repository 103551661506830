import React from 'react'

import useI18n from '@/common/hooks/useI18n'
import { PrefetchRequest } from '@/common/types'
import PlaceCard from '@/modules/property/components/PlaceCard'
import usePopularPlaces, {
  prefetchPopularPlaces,
} from '@/modules/property/hooks/usePopularPlaces'

import { Wrapper, StyledContainer, TitleOverlay, CardsWrapper } from './styled'
import { TITLE } from './translation'

const PopularPropertySection = () => {
  const { t } = useI18n()
  const { data: popularPlaces } = usePopularPlaces()

  if (!popularPlaces.length) return null

  return (
    <Wrapper>
      <StyledContainer>
        <div className='flex align-center justify-space-between mb-8 px-24'>
          <TitleOverlay>{t(TITLE, { _language: 'en' })}</TitleOverlay>
          <h1 className='text-24'>{t(TITLE)}</h1>
        </div>
        <CardsWrapper>
          {popularPlaces.map(popularPlace => (
            <PlaceCard
              key={`popular-place-${popularPlace.id}`}
              place={popularPlace}
            />
          ))}
        </CardsWrapper>
      </StyledContainer>
    </Wrapper>
  )
}

PopularPropertySection.prefetch = async (request: PrefetchRequest) => {
  await prefetchPopularPlaces(request)
}

export default PopularPropertySection

import cn from 'classnames'
import React from 'react'

import Badge from '@/common/components/Badge'
import Image from '@/common/components/Image'
import { LinkProps } from '@/common/components/Link'
import useI18n from '@/common/hooks/useI18n'
import { PRICE_PER_MONTH } from '@/common/translation'
import { floor } from '@/common/utils/number'
import { getTraslationObject } from '@/common/utils/object'
import { numberToString } from '@/common/utils/string'
import { getFileUrl, resolvePath } from '@/common/utils/url'
import { PLACE_DETAIL_PATH } from '@/modules/property/paths'
import { AVAILABLE } from '@/modules/property/translation'

import { Wrapper, Location } from './styled'

interface PlaceCardProps extends Omit<LinkProps, 'to'> {
  place: RentAPerfect.PopularCondo
}

const PlaceCard = ({ className, place, ...props }: PlaceCardProps) => {
  const { t } = useI18n()

  const priceList =
    !place.avgPrice || Array.isArray(place.avgPrice)
      ? []
      : (Object.values(place.avgPrice) as number[])
  const minPrice = floor(Math.min(...priceList), { precision: 3 })
  const maxPrice = floor(Math.max(...priceList), { precision: 3 })

  return (
    <Wrapper
      {...props}
      className={cn(className, 'flex flex-column')}
      to={resolvePath(PLACE_DETAIL_PATH, { placeId: place.id })}
    >
      <Image
        className='block'
        src={getFileUrl(place.CondoPhotos?.[0]?.url)}
        width='100%'
        height={180}
        cover
      />
      <div className='flex flex-1 flex-column justify-space-between pt-16 pb-24 px-24'>
        <div className='mb-12'>
          <Badge className='mb-12' color='#31b712'>
            {t(AVAILABLE)}: {numberToString(place._count.Rooms)}
          </Badge>
          <div className='text-18 text-semibold ellipsis-2 mb-8'>
            {t(getTraslationObject(place, 'name'))}
          </div>
          {place.district ? (
            <Location>{t(getTraslationObject(place, 'district'))}</Location>
          ) : null}
        </div>
        {priceList.length ? (
          <div className='text-14 text-medium'>
            {t(PRICE_PER_MONTH, {
              price:
                minPrice === maxPrice
                  ? `฿${numberToString(minPrice)}`
                  : `฿${numberToString(minPrice)} - ฿${numberToString(
                      maxPrice
                    )}`,
            })}
          </div>
        ) : null}
      </div>
    </Wrapper>
  )
}

export default PlaceCard

import React from 'react'

import ArticlesSection from './components/ArticlesSection'
import HeroSection from './components/HeroSection'
import JoinWithUsSection from './components/JoinWithUsSection'
import PopularPropertySection from './components/PopularPropertySection'

const HomeLandingPage = () => {
  return (
    <>
      <HeroSection />
      <PopularPropertySection />
      <ArticlesSection />
      <JoinWithUsSection />
    </>
  )
}

export default HomeLandingPage

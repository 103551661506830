import { useQuery } from 'react-query'

import useApis from '@/common/hooks/useApis'
import { PrefetchRequest } from '@/common/types'

const QUERY_KEY = 'article-media'

interface Props {
  mediaId?: number
}

const useArticleMedia = ({ mediaId }: Props) => {
  const { apis } = useApis()

  const { data = null, ...query } = useQuery(
    [QUERY_KEY, { mediaId }],
    () =>
      apis.article.getArticleMedia({ mediaId: mediaId!, _ignoreError: true }),
    { enabled: !!mediaId }
  )

  return { data, ...query }
}

export const prefetchArticleMedia = async (
  { apis, queryClient }: PrefetchRequest,
  { mediaId }: Props
) => {
  return mediaId
    ? queryClient.fetchQuery([QUERY_KEY, { mediaId }], () =>
        apis.article.getArticleMedia({ mediaId })
      )
    : null
}

export default useArticleMedia

import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  scroll-padding: 0 24px;
`

export const Arrow = styled.div.attrs({
  className: 'text-medium px-48 py-4 clickable',
})`
  font-size: 22px;
  line-height: 1;
  position: absolute;
  top: -5px;
`

export const ArrowLeft = styled(Arrow).attrs({
  children: '<',
})`
  background-image: linear-gradient(to left, transparent 0%, white 80%);
  padding-left: 8px;
  left: 0;
`

export const ArrowRight = styled(Arrow).attrs({
  children: '>',
})`
  background-image: linear-gradient(to right, transparent 0%, white 80%);
  padding-right: 8px;
  right: 0;
`

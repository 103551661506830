import { useQuery } from 'react-query'

import useApis from '@/common/hooks/useApis'
import { PrefetchRequest } from '@/common/types'

const QUERY_KEY = 'popular-places'

const usePopularPlaces = () => {
  const { apis } = useApis()

  const { data = [], ...query } = useQuery(QUERY_KEY, () =>
    apis.condo.getPopularCondos()
  )

  return { data, ...query }
}

export const prefetchPopularPlaces = async ({
  apis,
  queryClient,
}: PrefetchRequest) => {
  return queryClient.fetchQuery(QUERY_KEY, () => apis.condo.getPopularCondos())
}

export default usePopularPlaces

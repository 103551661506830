export const round = (value: number, { precision = 0 } = {}) => {
  const multiplier = 10 ** precision
  return Math.round(value / multiplier) * multiplier
}

export const floor = (value: number, { precision = 0 } = {}) => {
  const multiplier = 10 ** precision
  return Math.floor(value / multiplier) * multiplier
}

export const ceil = (value: number, { precision = 0 } = {}) => {
  const multiplier = 10 ** precision
  return Math.ceil(value / multiplier) * multiplier
}

import React, { HTMLAttributes } from 'react'

import { StyledBadge } from './styled'

interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  color?: string
  textColor?: string
}

const Badge = ({ color, textColor, ...props }: BadgeProps) => (
  <StyledBadge {...props} $color={color} $textColor={textColor} />
)

export default Badge

import cn from 'classnames'
import React from 'react'

import Gap from '@/common/components/Gap'
import useI18n from '@/common/hooks/useI18n'

import { Wrapper } from './styled'

interface ArticleCategoryTabsProps {
  items: RentAPerfect.ArticleCategory[]
  activeId: number
  onChange: (id: number) => void
}

const ArticleCategoryTabs = ({
  items,
  activeId,
  onChange,
}: ArticleCategoryTabsProps) => {
  const { t } = useI18n()

  return (
    <Wrapper>
      <Gap
        className={cn('width-fit-content mx-auto px-24')}
        space={44}
        mobileSpace={40}
      >
        {items.map(({ id, name }) => (
          <div
            key={`article-category-${id}`}
            className={cn(
              'text-14 text-medium clickable pb-2',
              id === activeId && 'text-primary text-bold border-bottom-primary'
            )}
            onClick={() => onChange(id)}
          >
            {t(name)}
          </div>
        ))}
      </Gap>
    </Wrapper>
  )
}

export default ArticleCategoryTabs

export const filterNotExistObjectValues = (obj: any) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null && !Number.isNaN(value)) {
      return { ...acc, [key]: value }
    }
    return acc
  }, {})
}

export const checkAllValuesExist = (obj: object, keys: string[]) => {
  return keys.every(
    key =>
      obj[key] !== undefined && obj[key] !== null && !Number.isNaN(obj[key])
  )
}

export const getTraslationObject = (obj: object, key: string) => {
  return {
    en: obj[`${key}EN`] || obj[`${key}En`] || obj[key],
    th: obj[`${key}TH`] || obj[`${key}Th`] || obj[key],
  }
}

import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import useApis from '@/common/hooks/useApis'
import useI18n from '@/common/hooks/useI18n'
import { PrefetchRequest } from '@/common/types'
import { prefetchArticleMedia } from '@/modules/article/hooks/useArticleMedia'

const QUERY_KEY = 'articles'

interface Props {
  categoryId?: number
}

const useArticles = (props: Props = {}) => {
  const { apis } = useApis()
  const { language } = useI18n()
  const queryClient = useQueryClient()

  const { data: articles = [], ...query } = useQuery([QUERY_KEY, props], () =>
    apis.article.getArticles(props)
  )

  const data = articles.filter(
    article =>
      (new URLSearchParams(article.url).get('lang') === 'th') ===
      (language === 'th')
  )

  useEffect(() => {
    data.forEach(article => {
      prefetchArticleMedia({ apis, queryClient } as PrefetchRequest, {
        mediaId: article.mediaId,
      })
    })
  }, [apis, queryClient, data])

  return { data, ...query }
}

export const prefetchArticles = async (
  { apis, queryClient }: PrefetchRequest,
  props: Props = {}
) => {
  return queryClient.fetchQuery([QUERY_KEY, props], () =>
    apis.article.getArticles(props)
  )
}

export default useArticles

import moment, { Moment } from 'moment'

export const numberToString = (
  value?: number,
  { decimals = 0, round = 10 ** -decimals, comma = true } = {}
) => {
  if (value === undefined || value === null) return ''

  const roundedValue = Math.round(value / round) * round

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping: comma,
  })

  return formatter.format(roundedValue)
}

export const dateToString = (
  date?: string | Moment | Date,
  { format = 'D MMM YYYY' } = {}
) => {
  if (!date) return ''

  const momentDate =
    date instanceof moment
      ? (date as Moment)
      : moment(new Date(date as string | number))

  return moment(momentDate).format(format)
}

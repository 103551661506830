import styled from 'styled-components'

import Container from '@/common/components/Container'
import Gap from '@/common/components/Gap'
import { media } from '@/common/utils/viewport'

export const Wrapper = styled.div`
  background-color: #fcfcfc;
  padding: 85px 0 80px;
  overflow: hidden;
`

export const StyledContainer = styled(Container).attrs({
  className: 'px-0',
})`
  position: relative;
  z-index: 0;
`

export const TitleOverlay = styled.div`
  color: rgba(137, 78, 150, 0.05);
  font-size: 60px;
  font-weight: 700;
  white-space: nowrap;
  position: absolute;
  top: -40px;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
`

export const CardsWrapper = styled(Gap).attrs({
  className: 'align-stretch-mobile mx-auto',
  space: 20,
  mobileSpace: 24,
  vertical: true,
  mobileVertical: false,
})`
  width: 100%;
  max-width: 768px;
  padding: 24px;
  overflow-x: auto;

  ${media.down('sm')`
    scroll-snap-type: x mandatory;
    scroll-padding: 24px;

    & > * {
      scroll-snap-align: start;
  }
  `}
`

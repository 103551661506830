import { useQuery } from 'react-query'

import useApis from '@/common/hooks/useApis'
import { PrefetchRequest } from '@/common/types'

const QUERY_KEY = 'article-categories'

const useArticleCategories = () => {
  const { apis } = useApis()

  const { data = [], ...query } = useQuery(QUERY_KEY, () =>
    apis.article.getArticleCategories()
  )

  return { data, ...query }
}

export const prefetchArticleCategories = async ({
  apis,
  queryClient,
}: PrefetchRequest) => {
  return queryClient.fetchQuery(QUERY_KEY, () =>
    apis.article.getArticleCategories()
  )
}

export default useArticleCategories

import React from 'react'

import Gap from '@/common/components/Gap'
import useI18n from '@/common/hooks/useI18n'
import { BROKER_REGISTER_PATH } from '@/modules/broker/paths'
import { BECOME_BROKER } from '@/modules/broker/translation'
import { OWNER_REGISTER_PATH } from '@/modules/owner/paths'
import { BECOME_OWNER } from '@/modules/owner/translation'

import { Wrapper, StyledContainer, StyledButtonLink } from './styled'
import { TITLE, DESCRIPTION } from './translation'

const JoinWithUsSection = () => {
  const { t } = useI18n()

  return (
    <Wrapper>
      <StyledContainer>
        <div className='text-24 text-bold mb-16'>{t(TITLE)}</div>
        <div className='text-14 text-thin mb-32'>{t(DESCRIPTION)}</div>
        <Gap className='align-center justify-center' space={24} mobileVertical>
          <StyledButtonLink
            to={OWNER_REGISTER_PATH}
            variant='filled'
            color='white'
          >
            {t(BECOME_OWNER)}
          </StyledButtonLink>
          <StyledButtonLink to={BROKER_REGISTER_PATH} variant='gradient'>
            {t(BECOME_BROKER)}
          </StyledButtonLink>
        </Gap>
      </StyledContainer>
    </Wrapper>
  )
}

export default JoinWithUsSection

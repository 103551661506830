import React, { useState, useEffect } from 'react'

import ButtonLink from '@/common/components/ButtonLink'
import useI18n from '@/common/hooks/useI18n'
import { VIEW_ALL } from '@/common/translation'
import { PrefetchRequest } from '@/common/types'
import ArticleCard from '@/modules/article/components/ArticleCard'
import useArticleCategories, {
  prefetchArticleCategories,
} from '@/modules/article/hooks/useArticleCategories'
import { prefetchArticleMedia } from '@/modules/article/hooks/useArticleMedia'
import useArticles, {
  prefetchArticles,
} from '@/modules/article/hooks/useArticles'
import getArticlesUrl from '@/modules/article/utils/getArticlesUrl'

import ArticleCategoryTabs from './components/ArticleCategoryTabs'
import { Wrapper, StyledContainer, TitleOverlay, CardsWrapper } from './styled'
import { TITLE } from './translation'

const MAX_ITEMS = 4

const ArticlesSection = () => {
  const { t, language } = useI18n()
  const { data: articles } = useArticles()
  const { data: categories } = useArticleCategories()

  const filteredCategories = categories.filter(category =>
    articles.some(article => article.categories.includes(category.id))
  )
  const [activeCategoryId, setActiveCategoryId] = useState(
    filteredCategories[0]?.id
  )

  useEffect(() => {
    if (activeCategoryId || !filteredCategories.length) return
    setActiveCategoryId(filteredCategories[0]?.id)
  }, [activeCategoryId, filteredCategories])

  if (!filteredCategories.length) return null

  return (
    <Wrapper>
      <StyledContainer>
        <div className='text-center mb-40 mb-16-mobile'>
          <TitleOverlay>{t(TITLE, { _language: 'en' })}</TitleOverlay>
          <h1 className='text-24'>{t(TITLE)}</h1>
        </div>
        <ArticleCategoryTabs
          items={filteredCategories}
          activeId={activeCategoryId}
          onChange={setActiveCategoryId}
        />
        <CardsWrapper>
          {articles
            .filter(article => article.categories.includes(activeCategoryId))
            .slice(0, MAX_ITEMS)
            .map(article => (
              <ArticleCard
                key={`article-${activeCategoryId}-${article.id}`}
                article={article}
                type='horizontal'
                mobileType='vertical'
              />
            ))}
        </CardsWrapper>
        <ButtonLink
          to={getArticlesUrl({ language })}
          className='mt-16 mx-auto'
          variant='outline'
        >
          {t(VIEW_ALL)}
        </ButtonLink>
      </StyledContainer>
    </Wrapper>
  )
}

ArticlesSection.prefetch = async (request: PrefetchRequest) => {
  const [articles] = await Promise.all([
    prefetchArticles(request),
    prefetchArticleCategories(request),
  ])

  await Promise.all(
    articles.map(({ mediaId }) => prefetchArticleMedia(request, { mediaId }))
  )
}

export default ArticlesSection

import moment from 'moment'
import React from 'react'

import Gap from '@/common/components/Gap'
import Image from '@/common/components/Image'
import useI18n from '@/common/hooks/useI18n'
import { READ_MORE } from '@/common/translation'
import useArticleMedia from '@/modules/article/hooks/useArticleMedia'

import { Wrapper, Title, Content, Date } from './styled'
import { ArticleCardType } from './types'

interface ArticleCardProps {
  article: RentAPerfect.Article
  type: ArticleCardType
  tabletType?: ArticleCardType
  mobileType?: ArticleCardType
}

const ArticleCard = ({
  article,
  type,
  tabletType = type,
  mobileType = tabletType,
}: ArticleCardProps) => {
  const { t } = useI18n()
  const { data: media } = useArticleMedia({ mediaId: article.mediaId })

  const vertical = type === 'vertical'
  const tabletVertical = tabletType === 'vertical'
  const mobileVertical = mobileType === 'vertical'

  return (
    <Wrapper
      to={article.url}
      $vertical={vertical}
      $tabletVertical={tabletVertical}
      $mobileVertical={mobileVertical}
    >
      <Gap
        vertical={vertical}
        tabletVertical={tabletVertical}
        mobileVertical={mobileVertical}
        space={vertical ? 16 : 20}
        tabletSpace={tabletVertical ? 16 : 20}
        mobileSpace={mobileVertical ? 16 : 20}
      >
        <Image
          className='block radius-4'
          src={media?.url}
          width={vertical ? '100%' : 162}
          tabletWidth={tabletVertical ? '100%' : 162}
          mobileWidth={mobileVertical ? '100%' : 162}
          height={vertical ? 100 : 114}
          tabletHeight={tabletVertical ? 100 : 114}
          mobileHeight={mobileVertical ? 100 : 114}
          cover
        />
        <div className='flex flex-1 flex-column justify-space-between'>
          <div className='mb-12'>
            <Title
              $vertical={vertical}
              $tabletVertical={tabletVertical}
              $mobileVertical={mobileVertical}
            >
              {article.title}
            </Title>
            <Content>{article.content}</Content>
          </div>
          <div className='flex justify-space-between text-12'>
            <Date>{moment(article.date).format('ll')}</Date>
            <div className='text-primary text-semibold'>{`${t(
              READ_MORE
            )} >`}</div>
          </div>
        </div>
      </Gap>
    </Wrapper>
  )
}

export default ArticleCard

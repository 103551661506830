import styled from 'styled-components'

import ButtonLink from '@/common/components/ButtonLink'
import Image from '@/common/components/Image'
import { media } from '@/common/utils/viewport'

const BANNER_HEIGHT_DESKTOP = 550
const BANNER_HEIGHT_TABLET = 400

export const Wrapper = styled.div`
  min-height: ${BANNER_HEIGHT_DESKTOP}px;
  position: relative;
  display: flex;
  z-index: 1;

  ${media.down('lg')`
    min-height: ${BANNER_HEIGHT_TABLET}px;
  `}
`

export const Banner = styled(Image).attrs({
  width: '100%',
  height: BANNER_HEIGHT_DESKTOP,
  tabletHeight: BANNER_HEIGHT_TABLET,
  cover: true,
})`
  position: absolute;
  z-index: -1;
`

export const ProjectName = styled.h1.attrs({
  className: 'text-44 text-48-tablet text-bold mt-40 mb-8 mt-0-tablet',
})`
  color: #531560;
  white-space: nowrap;
`

export const Slogan = styled.h2.attrs({
  className: 'text-32 text-24-mobile text-semibold mb-24',
})`
  color: rgba(83, 21, 96, 0.8);

  > span {
    white-space: nowrap;
  }

  ${media.up('lg')`
    max-width: 250px;
  `}
`

export const RegisterButton = styled(ButtonLink).attrs({
  className: 'mx-auto-tablet mb-48-tablet',
  buttonClassName: 'text-bold',
  variant: 'filled',
  color: 'white',
})`
  box-shadow: 0 3px 20px 0 rgba(137, 78, 150, 0.2);
`

export const SearchCard = styled.div.attrs({
  className: 'mx-auto-tablet',
})`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  width: 100%;
  max-width: 457px;
  margin: 0 85px;
  padding: 8px;

  > div {
    background: white;
    border-radius: 8px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  }
`

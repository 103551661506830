import cn from 'classnames'
import styled from 'styled-components'

import Link from '@/common/components/Link'
import { media } from '@/common/utils/viewport'

interface Props {
  $vertical: boolean
  $tabletVertical: boolean
  $mobileVertical: boolean
}

export const Wrapper = styled(Link).attrs({
  className: 'flex flex-column p-16',
})<Props>`
  width: 100%;
  background-color: white;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  min-width: ${({ $vertical }) => ($vertical ? '192px' : 'auto')};

  ${media.down('lg')`
    min-width: ${({ $tabletVertical }) => ($tabletVertical ? '192px' : 'auto')};
  `}

  ${media.down('sm')`
    min-width: ${({ $mobileVertical }) => ($mobileVertical ? '192px' : 'auto')};
  `}
`

export const Title = styled.div.attrs(
  ({ $vertical, $tabletVertical, $mobileVertical }: Props) => ({
    className: cn(
      'text-16 text-semibold mb-8',
      $vertical ? 'ellipsis-2' : 'ellipsis-1',
      $tabletVertical ? 'ellipsis-2-tablet' : 'ellipsis-1-tablet',
      $mobileVertical ? 'ellipsis-2-mobile' : 'ellipsis-1-mobile'
    ),
  })
)<Props>``

export const Content = styled.div.attrs({
  className: 'text-14 ellipsis-2',
})`
  line-height: 1.8;
`

export const Date = styled.div`
  color: #7b7b7b;
`

import styled from 'styled-components'

interface StyledBadgeProps {
  $color?: string
  $textColor?: string
}

export const StyledBadge = styled.div<StyledBadgeProps>`
  background-color: ${({ theme, $color }) => $color ?? theme.colors.primary};
  color: ${({ $textColor }) => $textColor ?? 'white'};
  width: fit-content;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  border-radius: 4px;
  padding: 2px 8px;
`

import styled from 'styled-components'

import Link from '@/common/components/Link'

export const Wrapper = styled(Link)`
  width: 100%;
  min-width: 255px;
  background-color: white;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
`

export const Location = styled.div.attrs({
  className: 'text-12 ellipsis-1',
})`
  color: #7b7b7b;
`
